.communityTabs__container {
  background-color: var(--asc-color-base-background);
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
}

.communityTabs__tab {
  display: flex;
  justify-content: center;
  padding: 1rem;
  flex-grow: 1;
  width: 100%;
  height: 1rem;
  align-items: center;

  --asc-icon-color: var(--asc-color-base-shade3);

  color: var(--asc-icon-color);
}

.communityTabs__tab[data-is-active='true'] {
  border-bottom: 2px solid var(--asc-color-primary-default);

  --asc-icon-color: var(--asc-color-base-default);
}
