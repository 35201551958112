.iconButton {
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: rgb(0 0 0 / 50%);
  border-radius: 50%;
  border: none;
  top: 6rem;
  left: 1.25rem;
  z-index: 9999;
  cursor: pointer;
}

.hyperLinkContainer {
  position: absolute;
  bottom: 6rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--asc-spacing-s2) var(--asc-spacing-m1) var(--asc-spacing-s2) var(--asc-spacing-m1);
  left: 50%;
  transform: translateX(-50%);
}

.hyperLinkContainer a {
  text-decoration: none;
}

.rendererContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.storyVideo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.muteCircleIcon {
  width: 100%;
  height: 100%;
}

.unmuteCircleIcon {
  width: 100%;
  height: 100%;
}

.loadingOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 90%);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storyImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--asc-story-image-background);
}

.storyImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.playStoryButton {
  color: var(--asc-color-white);
  cursor: pointer;
}

.pauseStoryButton {
  color: var(--asc-color-white);
  cursor: pointer;
}

.closeButton {
  color: var(--asc-color-white);
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.verifiedBadge {
  color: var(--asc-color-white);
}

.dotsButton {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  color: var(--asc-color-white);
}

.viewStoryInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.viewStoryCompostBarContainer {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  padding: var(--asc-spacing-s2);
  background-color: var(--asc-color-black);
  bottom: 0;
}

.viewStoryCompostBarViewIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--asc-color-white);
  gap: var(--asc-spacing-xxs2);
}

.viewStoryCompostBarEngagementContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--asc-color-white);
  gap: var(--asc-spacing-s2);
}

.viewStoryCompostBarEngagementIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--asc-color-white);
  gap: var(--asc-spacing-xxs2);
  border-radius: 50%;
  padding: var(--asc-spacing-s1) var(--asc-spacing-s2);
  background-color: #292b32;
}

.header {
  height: 5rem;
  padding: var(--asc-spacing-s2) var(--asc-spacing-m1) var(--asc-spacing-s2) var(--asc-spacing-m1);
}

.viewStoryContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--asc-color-black);
}

.viewStoryHeaderContainer {
  z-index: 9999;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: var(--asc-spacing-m3) var(--asc-spacing-m1) var(--asc-spacing-s2) var(--asc-spacing-m1);
  gap: var(--asc-spacing-s1);
}

.avatarContainer {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.addStoryButton {
  position: absolute;
  bottom: 0;
  right: 0;
}

.addStoryButton:hover {
  cursor: pointer;
}

.viewStoryHeaderListActionsContainer {
  display: flex;
  gap: var(--asc-spacing-m2);
  justify-content: flex-end;
  align-items: center;
}

.viewStoryHeadingInfoContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: var(--asc-spacing-s2);
  align-items: center;
}

.viewStoryHeading {
  cursor: pointer;
  display: flex;
  gap: var(--asc-spacing-xxs2);
  color: var(--asc-color-white);
  font-size: var(--asc-text-font-size-sm);
  font-style: normal;
  font-weight: var(--asc-text-font-weight-bold);
  line-height: var(--asc-line-height-md);
  letter-spacing: -0.24px;
  margin-right: var(--asc-spacing-xxs2);
  align-items: center;
}

.viewStoryHeadingTitle {
  width: auto;
  max-width: 11.688rem;
}

.viewStorySubHeading {
  display: inline-flex;
  gap: var(--asc-spacing-xxs2);
  margin-bottom: var(--asc-spacing-xxs2);
  color: var(--asc-color-white);
  font-size: var(--asc-text-font-size-xs);
  font-style: normal;
  font-weight: var(--asc-text-font-weight-normal);
  line-height: var(--asc-line-height-md);
  letter-spacing: -0.1px;
}

.story {
  display: flex;
  position: relative;
  overflow: hidden;
}

.storyContent {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  flex: 1;
}

.actionButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: var(--asc-spacing-s1);
  border: none;
  color: var(--asc-color-base-default);
  background-color: var(--asc-color-background-default);
  padding: var(--asc-spacing-m1) var(--asc-spacing-m2);
  cursor: pointer;
  border-radius: var(--asc-border-radius-sm);
}

.actionButton:hover {
  background-color: var(--asc-color-base-shade4);
}

.navigationOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 10;
}

.leftOverlay {
  left: 0;
}

.rightOverlay {
  right: 0;
}

.imageFit {
  object-fit: contain;
}

.imageFill {
  object-fit: cover;
}
