.storyReactionButton {
  all: unset;
  display: flex;
  padding: var(--asc-spacing-s1) var(--asc-spacing-s2);
  align-items: center;
  gap: var(--asc-spacing-xxs3);
  border-radius: var(--asc-border-radius-full);
  background: var(--asc-color-secondary-default);
  color: var(--asc-color-white);
  cursor: pointer;
}

.storyReactionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--asc-spacing-xxs2);
}
