.topNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--asc-color-background-default);
  height: 3.625rem;
}

.topNavigationLeftPane {
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  align-items: center;
}

.topNavigationRightPane {
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  align-items: center;
}
