.exploreCreateCommunityButton {
  display: flex;
  padding: 0.625rem 1rem 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background: var(--asc-color-primary-default);
  cursor: pointer;
}

.exploreCreateCommunityButton__icon {
  fill: var(--asc-color-white);
  height: 1.25rem;
  width: 1.25rem;
}

.exploreCreateCommunityButton__text {
  color: var(--asc-color-white);
}
