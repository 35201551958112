.exploreCommunityCategories {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.exploreCommunityCategories::-webkit-scrollbar {
  display: none;
}

.exploreCommunityCategories__seeMore {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.125rem;
  padding: 0 0.75rem;
  border: 1px solid var(--asc-color-base-shade4);
  background-color: var(--asc-color-background-default);
  height: 2.25rem;
  flex-wrap: nowrap;
  color: var(--asc-color-base-default);
  text-wrap: nowrap;
}

.exploreCommunityCategories__seeMoreIcon {
  width: 1rem;
  height: 1rem;
  fill: var(--asc-color-base-default);
}

.exploreCommunityCategories__seeMore:hover {
  background-color: var(--asc-color-background-shade1);
}

.exploreCommunityCategories__categoryChip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
}
