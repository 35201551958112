.editorPlaceholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 4.5rem;
  left: 1rem;
  user-select: none;
  pointer-events: none;
}

.editorParagraph {
  height: 100%;
  width: 100%;
  color: var(--asc-color-base-default);
  background-color: var(--asc-color-background-default);
}

.editorContainer {
  height: 100%;
  font-size: var(--asc-text-font-size-md);
  color: var(--asc-color-base-default);
  background-color: var(--asc-color-background-default);
  padding: 0 1rem;
}

.editorContainer :focus {
  border: none;
  outline: none;
}

.editorRoot {
  width: 100%;
  background-color: var(--asc-color-secondary-shade4);
  border-radius: 1.25rem;
  min-height: 2.5rem;
  padding: 0.625rem 0.75rem;
  border: 0 solid var(--asc-color-background-default);
  outline: 0;
}

.editorLink {
  color: var(--asc-color-primary-shade1);
  text-decoration: none;
}

.editorLink:hover {
  text-decoration: underline;
}

.postTextField__mentionInterceptor {
  width: 100%;
  height: 1px;
  background-color: var(--asc-color-background-default);
}
