.exploreCategories__categoryChipSkeleton {
  width: 5.625rem;
  height: 2.25rem;
  flex-shrink: 0;
  border-radius: 2.5rem;
  background: var(--asc-color-base-shade4);
  animation: skeleton-pulse 1.5s ease-in-out infinite;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
