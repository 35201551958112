.postCreationButton {
  background-color: var(--asc-color-secondary-shade4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.postCreationButton__icon {
  fill: var(--asc-color-secondary-default);
  width: 1rem;
  height: 1rem;
}
