.overlayLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 9999;
}

.overlayRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 9999;
}

.viewStoryContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
}

.viewStoryOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 16%) 55.05%, rgb(255 255 255 / 0%) 96.52%);
  z-index: 3;
}

.hiddenInput {
  display: none;
}

.viewStoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 23.4375rem;
  flex-shrink: 0;
}

.storyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
  overflow: hidden;
}

.deleteIcon {
  fill: var(--asc-color-base-default);
}

/* TODO: should standardize media variable eg. mobile screen, tablet screen ,desktop screen */

/* Mobile-first approach */
@media (width >= 768px) {
  .overlayLeft,
  .overlayRight {
    display: none;
  }

  .arrowButton {
    display: block;
  }
}
