.communityCardImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.communityCardImage__placeholderImage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--asc-color-base-shade3);
  width: 100%;
  height: 100%;
}

.communityCardImage__placeholderImageIcon {
  width: 4rem;
  height: 2.364rem;
}
