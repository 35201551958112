.communityFeed__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.communityFeed__loadingIndicator {
  display: flex;
  justify-content: center;
  padding: var(--asc-spacing-m1) 0;
}

.communityFeed__observerTarget {
  height: var(--asc-spacing-m1);
  width: 100%;
}

.communityFeed__post {
  margin-bottom: var(--asc-spacing-m1);
  border-radius: var(--asc-border-radius-md);
  overflow: hidden;
}

.communityFeed__spinner {
  animation: spin 1s linear infinite;
}

.communityFeed__postContent {
  padding-bottom: 0.75rem;
  background-color: var(--asc-color-base-shade4);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.communityFeed__postSkeleton {
  background-color: var(--asc-color-background-default);
  padding: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%);
}

.communityFeed__postSkeletonHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.communityFeed__postSkeletonAvatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--asc-color-base-shade4);
  margin-right: 0.75rem;
}

.communityFeed__postSkeletonUserInfo {
  display: flex;
  flex-direction: column;
}

.communityFeed__postSkeletonUsername {
  width: 6rem;
  height: 1rem;
  background-color: var(--asc-color-base-shade4);
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.communityFeed__postSkeletonSubtitle {
  width: 4rem;
  height: 0.75rem;
  background-color: var(--asc-color-base-shade4);
  border-radius: 0.25rem;
}

.communityFeed__postSkeletonContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.communityFeed__postSkeletonLine {
  height: 0.75rem;
  background-color: var(--asc-color-base-shade4);
  border-radius: 0.25rem;
  width: 100%;
}

.communityFeed__postSkeletonLine:first-child {
  width: 75%;
}

.communityFeed__postSkeletonLine:nth-child(2) {
  width: 100%;
}

.communityFeed__postSkeletonLine:last-child {
  width: 50%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.communityFeed__postSkeletonAvatar,
.communityFeed__postSkeletonUsername,
.communityFeed__postSkeletonSubtitle,
.communityFeed__postSkeletonLine {
  animation: pulse 1.5s ease-in-out infinite;
}

.communityFeed__emptyPost {
  margin: 5rem auto 3rem;
  text-align: center;
}

.communityFeed__emptyPostIcon {
  fill: var(--asc-color-base-shade4);
  width: 3.75rem;
  height: 2.8125rem;
}

.communityFeed__emptyPostText {
  color: var(--asc-color-base-shade4);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  margin-top: 0.5rem;
}

.communityFeed__announcePost {
  padding-bottom: 0.75rem;
  background-color: var(--asc-color-base-shade4);
}
