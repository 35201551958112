.iconButton {
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: color(var(--asc-color-black), 0.5);
  border-radius: 50%;
  border: none;
  top: 6rem;
  left: 1.25rem;
  z-index: 9999;
  cursor: pointer;
}

.rendererContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.storyVideo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.muteCircleIcon {
  width: 100%;
  height: 100%;
}

.unmuteCircleIcon {
  width: 100%;
  height: 100%;
}

.loadingOverlay {
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(var(--asc-color-black) 0.9);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--asc-color-base-shade3);
}

.storyImage {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.playStoryButton {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--asc-color-white);
  cursor: pointer;
}

.pauseStoryButton {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--asc-color-white);
  cursor: pointer;
}

.closeButton {
  fill: var(--asc-color-white);
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.verifiedBadge {
  fill: var(--asc-color-white);
}

.dotsButton {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  color: var(--asc-color-white);
}

.viewStoryInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.viewStoryCompostBarContainer {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  padding: 0.75rem;
  background-color: var(--asc-color-black);
  bottom: 0;
}

.viewStoryCompostBarViewIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--asc-color-white);
  gap: 0.25rem;
}

.viewStoryCompostBarEngagementContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--asc-color-white);
  gap: 0.75rem;
}

.viewStoryCompostBarEngagementIconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--asc-color-white);
  gap: 0.25rem;
  border-radius: 50%;
  padding: 0.5rem 0.625rem;
  background-color: var(--asc-color-secondary-default);
}

.storyContent {
  flex: 1;
}

.header {
  height: 5rem;
  padding: 0.75rem 1rem 0.625rem;
}

.viewStoryContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--asc-color-black);
}

.viewStoryHeaderContainer {
  z-index: 99999;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem 0.625rem;
  gap: 0.5rem;
}

.avatarContainer {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.addStoryButton {
  position: absolute;
  bottom: 0;
  right: 0;
}

.addStoryButton:hover {
  cursor: pointer;
}

.viewStoryHeaderListActionsContainer {
  display: flex;
  gap: 1.25rem;
  justify-content: flex-end;
  align-items: center;
}

.viewStoryHeadingInfoContainer {
  display: inline-flex;
  justify-content: space-between;
  gap: 0.75rem;
  align-items: center;
}

.viewStoryHeading {
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
  color: var(--asc-color-white);
  font-size: var(--asc-text-font-size-sm);
  font-style: normal;
  font-weight: var(--asc-text-font-weight-bold);
  line-height: var(--asc-line-height-md);
  letter-spacing: -0.24px;
  margin-right: 0.25rem;
  align-items: center;
}

.viewStoryHeadingTitle {
  width: auto;
  max-width: 11.688rem;
}

.viewStorySubHeading {
  display: inline-flex;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
  color: var(--asc-color-white);
  font-size: var(--asc-text-font-size-xs);
  font-style: normal;
  font-weight: var(--asc-text-font-weight-normal);
  line-height: var(--asc-line-height-md);
  letter-spacing: -0.1px;
}
