.socialHomePage {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--asc-color-background-default);
}

.socialHomePage__topBar {
  top: 0;
  position: absolute;
  width: 100%;
  height: 7.5rem;
  background-color: var(--asc-color-background-default);
}

.socialHomePage__topNavigation {
  padding-left: 1rem;
  padding-right: 1rem;
}

.socialHomePage__tabs {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  overflow-x: scroll;
  width: 100%;
  padding: 0.75rem 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.socialHomePage__tabs::-webkit-scrollbar {
  display: none;
}

.socialHomePage__contents {
  position: absolute;
  top: 7.5rem;
  width: 100%;
  height: calc(100% - 7.5rem);
  overflow: hidden scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.socialHomePage__contents::-webkit-scrollbar {
  display: none;
}

.socialHomePage__createPostMenu {
  position: absolute;
  right: 1rem;
  top: 3.25rem;
  width: 12.5rem;
}
