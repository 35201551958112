.editPostButton {
  display: flex;
  font-weight: var(--asc-text-font-weight-normal);
  color: var(--asc-color-primary-default);
}

.editPostButton:disabled {
  display: flex;
  font-weight: var(--asc-text-font-weight-normal);
  color: var(--asc-color-primary-shade2);
  cursor: not-allowed;
}
