.communityInfo__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.communityInfo__wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.communityInfo__count {
  color: var(--asc-color-base-default);
}

.communityInfo__title {
  color: var(--asc-color-base-shade2);
}
