.recommendedCommunities {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 0.75rem;
  overflow-x: scroll;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.recommendedCommunityCard {
  border-radius: var(--asc-border-radius-sm);
  border: 1px solid var(--asc-color-base-shade4);
  width: 16.75rem;
  height: calc(7.8rem + 6rem);
}

.recommendedCommunityCard__image {
  width: 100%;
  height: 7.8rem;
  overflow: hidden;
}

.recommendedCommunityCard__img {
  border-radius: var(--asc-border-radius-sm) var(--asc-border-radius-sm) 0 0;
}

.recommendedCommunityCard__content {
  display: flex;
  flex-direction: column;
  padding: 0.62rem;
  width: 100%;
}

.recommendedCommunityCard__bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 0.5rem;
  width: 100%;
}

.recommendedCommunityCard__content__left {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 0.25rem;
  flex-shrink: 1;
  width: 9rem;
}

.recommendedCommunities__contentTitle {
  display: flex;
  align-items: center;
  gap: 0.12rem;
  width: 100%;
}

.recommendedCommunities__content__right {
  display: flex;
  align-items: end;
  align-self: flex-end;
  flex: 0 0 4.2rem;
}

.recommendedCommunityCard__name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recommendedCommunityCard__communityName {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
}

.recommendedCommunityCard__communityName__private,
.recommendedCommunityCard__communityName__official {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.22rem;
  padding-bottom: 0.28rem;
}
