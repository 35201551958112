.viewStoryCompostBarContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--asc-spacing-s2);
  background-color: var(--asc-color-black);
  color: var(--asc-color-white);
  position: absolute;
  bottom: 0;
  z-index: 99999;
}

.viewStoryFailedCompostBarContainer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--asc-spacing-l4);
  padding: var(--asc-spacing-s2);
  background-color: var(--asc-color-alert-default);
  color: var(--asc-color-white);
  z-index: 0;
}

.viewStoryFailedCompostBarWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--asc-spacing-s1);
  width: 100%;
}

.viewStoryUploadingWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--asc-spacing-s1);
}

.viewStoryCompostBarViewIconContainer {
  font-weight: var(--asc-text-font-weight-bold);
  color: var(--asc-color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--asc-spacing-xxs2);
}

.viewStoryCompostBarEngagementContainer {
  font-weight: var(--asc-text-font-weight-bold);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--asc-spacing-s2);
}

.viewStoryCommentButton {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 0.5rem;
  border-radius: 24px;
  background: var(--asc-color-secondary-default);
}

.viewStoryCommentIcon {
  fill: var(--asc-color-white);
}
