.emptyNewsfeed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--asc-color-background-default);
}

.emptyNewsfeed__text {
  padding-bottom: 1.0625rem;
}
