.container {
  position: relative;
  background-color: var(--asc-color-background-default-sh);
}

.innerContainer {
  width: calc(100% - (var(--asc-spacing-m1) * 2));
  margin: var(--asc-spacing-xxs2) auto;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--asc-spacing-s2);
  padding: var(--asc-spacing-s1) 0;
  max-width: 100%;
}

.header__title {
  color: var(--asc-color-base-default);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header__avatar {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
}

.header__detail {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--asc-spacing-xxs1);
  flex: 1 1 auto;
  overflow: hidden;
}

.content__text {
  color: var(--asc-color-base-default);
  margin: var(--asc-spacing-s1) 0;
}

.content__imageContainer {
  aspect-ratio: 1 / 1;
}

.content__image {
  margin: var(--asc-spacing-s1) 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--asc-border-radius-md);
}

.footer {
  background-color: var(--asc-color-background-shade1);
  padding: var(--asc-spacing-m1) var(--asc-spacing-s2);
  display: grid;
  grid-template:
    'left right' auto
    / minmax(0, 3fr) minmax(0, 1fr);
  align-items: center;
  justify-content: start;
  margin-top: var(--asc-spacing-s1);
  width: 100%;
}

.footer[data-has-url='true'] {
  cursor: pointer;
}

.footer__content__description {
  color: var(--asc-color-base-shade1);
}

.footer__content__headline {
  color: var(--asc-color-base-default);
}

.footer__left {
  grid-area: left;
  word-wrap: break-word;
}

.footer__right {
  grid-area: right;
  display: flex;
  justify-content: center;
  align-items: center;
  place-self: center;
  max-width: 100%;
}

.footer__content__button {
  background-color: var(--asc-color-primary-default);
  color: var(--asc-color-white);
  padding: 0.375rem 0.75rem;
  border-radius: var(--asc-border-radius-md);
  max-width: 100%;
  overflow: hidden;
}

.footer__content__button__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.infoIcon__button {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 0.25rem;
  top: 0.25rem;
  cursor: pointer;
}

.infoIcon {
  fill: var(--asc-color-base-shade3);
}
