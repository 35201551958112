.storyButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.storyButton svg {
  width: 2rem;
  height: 1.75rem;
  fill: var(--asc-color-base-inverse);
}

.desktopOnly {
  display: none;
}

@media screen and (width >= 768px) {
  .desktopOnly {
    display: flex;
  }
}
