.progressBarContainer {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  height: 0.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.progressSegment {
  height: 100%;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 0.0625rem;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background-color: var(--asc-color-white);
  transition: width 0.1s linear;
}
