.editPost {
  position: relative;
  display: block;
  background-color: var(--asc-color-background-default);
  height: 100%;
}

.editPost__topBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--asc-spacing-m1);
}

.editPost__status {
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 0;
  left: 1rem;
  display: flex;
  justify-content: start;
  padding: unset;
  font-size: var(--asc-text-font-size-md);
  gap: 0.75rem;
  margin: 0 auto 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--asc-box-shadow-03);
  background-color: var(--asc-color-secondary-default);
  color: var(--asc-color-background-default);
}

.editPost_infoIcon {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--asc-color-background-default);
}

.editPost__notiWrap {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.editPost__formMediaAttachment {
  max-height: 100%; /* for form scroll */
  overflow-y: scroll;
}

.editPost__formMediaAttachment::-webkit-scrollbar {
  width: 0.5rem;
}

/* vaul */

.drawer__content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  outline: none;
}

.mentionTextInput_item {
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 12.5rem;
  overflow-y: scroll;
  background-color: var(--asc-color-background-default);
  bottom: 0;
}

.mentionTextInput_item::-webkit-scrollbar {
  display: none;
}
