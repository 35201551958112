.exploreCommunityEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--asc-color-background-default);
  gap: 1rem;
}

.exploreCommunityEmpty__text {
  padding-bottom: 1.0625rem;
  color: var(--asc-color-base-shade3);
}
