.thumbnail__container {
  max-width: 100%;
  display: grid;
  gap: 0.5rem;
  padding: 0 1rem;
  width: 100%;
}

.thumbnail__container[data-images-amount='1'] {
  grid-template-columns: repeat(1, 1fr);
  height: 21rem;
}

.thumbnail__container[data-images-amount='2'] {
  grid-template-columns: repeat(2, 1fr);
  max-height: 21rem;
}

.thumbnail__container[data-images-amount='3'] {
  grid-template-columns: repeat(3, 1fr);
}

.thumbnail__wrapper {
  position: relative;
  text-align: center;
  width: 100%;
  height: 21rem;
}

.thumbnail__wrapper[data-images-height='true'] {
  height: 6.8rem;
}

.thumbnail {
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail__closeButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.19rem;
  background-color: rgb(0 0 0 / 50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}

.thumbnail__closeIcon {
  fill: var(--asc-color-primary-shade4);
  width: 1.25rem;
  height: 1.25rem;
}

.icon__status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.75rem;
  height: 1.75rem;
  fill: var(--asc-color-primary-shade4);
}

.playIcon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.32rem;
  background-color: rgb(0 0 0 / 30%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
