.categoryChip {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  border-radius: 1.125rem;
  border: 1px solid var(--asc-color-base-shade4);
  background-color: var(--asc-color-background-default);
}

.categoryChip:hover {
  background-color: var(--asc-color-background-shade1);
}

.categoryChip__image {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  overflow: hidden;
}

.categoryChip__text {
  color: var(--asc-color-base-default);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
