.draftPageContainer {
  aspect-ratio: 9 / 16;
  max-width: 23.4375rem; /* 375px */
  max-height: 100%;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (width <= 768px) {
  .draftPageContainer {
    max-width: 100%;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    aspect-ratio: auto;
  }
}

.headerContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.storyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
  overflow: hidden;
  background-color: var(--asc-color-black);
}

.header {
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: var(--asc-spacing-s1);
  background-color: transparent;
  color: var(--asc-color-white);
}

.topRightButtons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: var(--asc-spacing-s2);
}

.mainContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: var(--asc-border-radius-lg);
  background: linear-gradient(
    180deg,
    var(--asc-draft-image-container-color-0, var(--asc-color-black)) 0%,
    var(--asc-draft-image-container-color-last, var(--asc-color-black)) 100%
  );
  position: relative;
  width: 100%;
  height: 100%;
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--asc-spacing-m1);
  background-color: var(--asc-color-black);
  width: 100%;
}

.videoPreview {
  max-height: calc(100% - 4.5rem);
  width: 100%;
  height: 100%;
  background-color: var(--asc-color-black);
  object-fit: contain;
}

.hyperLinkContainer {
  position: absolute;
  padding: 1rem;
  display: flex;
  justify-content: center;
  bottom: 6rem;
  left: 0;
  right: 0;
}

.playPauseButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  color: var(--asc-color-white);
  font-size: 3rem;
  cursor: pointer;
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: rgb(255 255 255 / 30%);
}

.progressFill {
  height: 100%;
  background-color: var(--asc-color-white);
  transition: width 0.1s linear;
}

.userInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.name {
  font-size: 16px;
  font-weight: bold;
  color: var(--asc-color-black);
}

.description {
  font-size: 14px;
}

.storyPreview {
  position: relative;
  width: 100%;
  height: 100%;
}
